import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from "./StitchPattern.module.css";
import {Typography} from "@mui/material";
import pattern35 from './img/pattern/35.png'
import pattern70 from './img/pattern/70.png'
import pattern137 from './img/pattern/137.png'
import pattern192 from './img/pattern/192.png'
import pattern248 from './img/pattern/248.png'

import selected35 from './img/pattern/selected/35.png'
import selected70 from './img/pattern/selected/70.png'
import selected137 from './img/pattern/selected/137.png'
import selected192 from './img/pattern/selected/192.png'
import selected248 from './img/pattern/selected/248.png'

import render35 from './img/render/35.png'
import render70 from './img/render/70.png'
import render137 from './img/render/137.png'
import render192 from './img/render/192.png'
import render248 from './img/render/248.png'
import {SECTIONS} from "./img/sections";
import {SUBSECTIONS} from "./img/subsections";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {StitchGrid} from "./StitchGrid";

const totalSize = 64; // Total size of the stitchData (64x64)
const sectionSize = totalSize / 2; // Size of each section (32x32)
const subSectionSize = sectionSize / 2; // Size of each subsection (16x16)
const StitchPattern = () => { // Assuming stitchData is passed as a prop
    const [isLoading, setIsLoading] = useState(false);
    const [stitchData, setStitchData] = useState([]);
    const [variationId, setVariationId] = useState(null);
    const [selectedSection, setSelectedSection] = useState({ x: 0, y: 0 });
    const [selectedSubSection, setSelectedSubSection] = useState({ x: 0, y: 0 });
    const [filledCells, setFilledCells] = useState({ x: 5, y: 5 });
    const [selectedCell, setSelectedCell] = useState(null);
    const location = useLocation();

    const cellsMapId = () => {
        return [
            variationId,
            selectedSection.x,
            selectedSection.y,
            selectedSubSection.x,
            selectedSubSection.y
        ].join('-');
    }

    useEffect(() => {
        const loadedFilledCells = localStorage.getItem(cellsMapId());
        if(loadedFilledCells) {
            setFilledCells(JSON.parse(loadedFilledCells));
        } else {
            setFilledCells(null);
        }
    }, [selectedSection, selectedSubSection, variationId]);

    useEffect(() => {
        if(filledCells) {
            localStorage.setItem(cellsMapId(), JSON.stringify(filledCells));
        } else {
            localStorage.removeItem(cellsMapId());
        }
    }, [filledCells]);

    useEffect(() => {
        console.log(location);
        const searchParams = new URLSearchParams(location.search);
        const variationId = searchParams.get('id');
        setVariationId(variationId);
    }, [location]);

    useEffect(() => {
        const fetchStitchData = async () => {
            setIsLoading(true);

            try {
                if (!variationId) {
                    console.error('No variation ID provided');
                    // Handle missing ID appropriately
                    return;
                }

                const response = await axios.get(`/api/puzzle/${variationId}/data`);
                setStitchData(response.data.data);
            } catch (error) {
                console.error('Error fetching stitch data:', error);
                // Handle error appropriately
            } finally {
                setIsLoading(false);
            }
        };

        fetchStitchData();
    }, [variationId]);

    const getSubSectionData = () => {
        if (stitchData.length === 0) {
            return [];
        }

        const startX = selectedSection.x * sectionSize + selectedSubSection.x * subSectionSize;
        const startY = selectedSection.y * sectionSize + selectedSubSection.y * subSectionSize;

        let subSectionData = [];

        for (let y = startY; y < startY + subSectionSize; y++) {
            if (!stitchData[y]) {
                continue;
            }

            let row = [];
            for (let x = startX; x < startX + subSectionSize; x++) {
                if (stitchData[y][x] === undefined) {
                    row.push(0);
                } else {
                    row.push(stitchData[y][x]);
                }
            }
            subSectionData.push(row);
        }

        return {
            data: subSectionData,
            startX,
            startY
        };
    };



    const renderStitchGrid = () => {
        const startX = selectedSection.x * sectionSize + selectedSubSection.x * subSectionSize;
        const startY = selectedSection.y * sectionSize + selectedSubSection.y * subSectionSize;


        return (
            <StitchGrid
                onClick={(x,y) => onCellClick(x,y)}
                countX={subSectionSize}
                countY={subSectionSize}
                stitchData={stitchData}
                filledCells={filledCells}
                selectedCell={selectedCell}
                startX={startX}
                startY={startY}
                renderNumbers={true}
            ></StitchGrid>
        )
        //
        // const subSectionData = getSubSectionData();
        // const rows = subSectionData.data.map((row, rowIndex) => (
        //     <div key={rowIndex} style={{ display: 'flex' }}>
        //         {renderStitchCols(row, subSectionData.startX, subSectionData.startY + rowIndex)}
        //     </div>
        // ));
        //
        // const numberRow = [];
        // for(let i = 0; i <= 16; i++) {
        //     numberRow.push(
        //         <div key={'colNumber-' + i} className={styles.colRowNumberCell}>
        //             { i > 0 ? subSectionData.startX+i : ''}
        //         </div>
        //     );
        //
        // }
        //
        // rows.unshift(
        //     <div key='colNumbers' style={{ display: 'flex' }}>
        //         {numberRow}
        //     </div>
        // );
        // return rows;
    };

    const renderStitchCols= (row, startColNumber, rowNumber) => {
        const cols = row.map((color, colIndex) => (
            <div
                key={colIndex}
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundImage: `url(${getImageFromNumber(color, startColNumber + colIndex, rowNumber)})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center'
                }}
                onClick={() => onCellClick(startColNumber + colIndex, rowNumber)}
            />
        ));

        cols.unshift(
            <div key='rowNumbers' className={styles.colRowNumberCell}>
                {rowNumber + 1}
            </div>
        );

        return cols;
    }

    const onCellClick = (x,y) => {
        const selected = selectedCell && selectedCell.x === x && selectedCell.y === y;
        if(!selected) {
            setSelectedCell({x,y});
        } else {
            setFilledCells({x,y});
            setSelectedCell(null);
        }
    }

    const resetFill = () => {
        setFilledCells(null);
        setSelectedCell(null);
    }

    const getImageFromNumber = (number, x, y) => {
        let render = false;
        if(filledCells) {
            const startX = selectedSection.x * sectionSize + selectedSubSection.x * subSectionSize;
            const startY = selectedSection.y * sectionSize + selectedSubSection.y * subSectionSize;
            const currRelativePos = ((y - startY) * subSectionSize) + (x - startX);
            const filledRelativePos = ((filledCells.y - startY) * subSectionSize) + (filledCells.x - startX);
            render = filledRelativePos >= currRelativePos;
        }

        const selected = selectedCell && selectedCell.x === x && selectedCell.y === y;
        
        if(render) {
            switch (number) {
                case 35: return render35;
                case 70: return render70;
                case 137: return render137;
                case 192: return render192;
                case 248: return render248;
                default: return render35;
            }    
        } else {
            switch (number) {
                case 35: return selected ? selected35 : pattern35;
                case 70: return selected ? selected70 : pattern70;
                case 137: return selected ? selected137 : pattern137;
                case 192: return selected ? selected192 : pattern192;
                case 248: return selected ? selected248 : pattern248;
                default: return selected ? selected35 : pattern35;
            }    
        }
        
    };

    const getSectionImage = (sectionIndex) => {
        const pos = { x: sectionIndex % 2, y: Math.floor(sectionIndex / 2) };
        return SECTIONS[pos.x][pos.y];
    }

    const getSubSectionImage = (subsectionIndex) => {
        const pos = { x: subsectionIndex % 2, y: Math.floor(subsectionIndex / 2) };
        return SUBSECTIONS[pos.x][pos.y];
    }

    const createSectionButtons = () => {
        const buttons = [0, 1, 2, 3].map((sectionIndex) => (
            <div
                onClick={() => setSelectedSection({ x: sectionIndex % 2, y: Math.floor(sectionIndex / 2) })}
                style={{
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${getSectionImage(sectionIndex)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    margin: '5px',
                    border: `3px solid ${(selectedSection.x === sectionIndex % 2 && selectedSection.y === Math.floor(sectionIndex / 2)) ? '#e91e63' : 'transparent'}`
                }}
            ></div>
        ));

        return (
            <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
                {buttons}
            </Container>
        )
    };

    const createSubSectionButtons = () => {
        const buttons = [0, 1, 2, 3].map((subSectionIndex) => (
            <div
                onClick={() => setSelectedSubSection({ x: subSectionIndex % 2, y: Math.floor(subSectionIndex / 2) })}
                style={{
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${getSubSectionImage(subSectionIndex)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    margin: '5px',
                    border: `3px solid ${(selectedSubSection.x === subSectionIndex % 2 && selectedSubSection.y === Math.floor(subSectionIndex / 2)) ? '#e91e63' : 'transparent'}`
                }}
            ></div>
        ));
        return (
            <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
                {buttons}
            </Container>
        )
    };

    return (
        <div className={styles.pageContainer}>

            {stitchData.length > 0 && (
                <Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant='h2' sx={{marginBottom: '20px'}} >Сборка мозаики</Typography>
                    <Typography variant='h3'>Выберите основу</Typography>
                    <div>{createSectionButtons()}</div>
                    <Typography variant='h4'>Выберите область сборки на основе</Typography>
                    <div>{createSubSectionButtons()}</div>
                    <div>{renderStitchGrid()}</div>
                </Container>
            )}

            {isLoading && (
                <Typography variant='h3'>Загрузка мозаики...</Typography>
            )}

            {filledCells && (
                <Button
                    component="label"
                    variant="outlined"
                    onClick={() => resetFill()}
                    sx={{margin: '5px'}}
                >
                    Очистить
                </Button>
            )}



        </div>
    );
};

export default StitchPattern;
