import React from "react";
import styles from "./StitchPattern.module.css";
import render35 from "./img/render/35.png";
import render70 from "./img/render/70.png";
import render137 from "./img/render/137.png";
import render192 from "./img/render/192.png";
import render248 from "./img/render/248.png";
import selected35 from "./img/pattern/selected/35.png";
import pattern35 from "./img/pattern/35.png";
import selected70 from "./img/pattern/selected/70.png";
import pattern70 from "./img/pattern/70.png";
import selected137 from "./img/pattern/selected/137.png";
import pattern137 from "./img/pattern/137.png";
import selected192 from "./img/pattern/selected/192.png";
import pattern192 from "./img/pattern/192.png";
import selected248 from "./img/pattern/selected/248.png";
import pattern248 from "./img/pattern/248.png";

export const StitchGrid = ({
    stitchData,
    startX,
    startY,
    countX,
    countY,
    filledCells,
    forceRender,
    selectedCell,
    onClick,
    pixelSize,
    renderNumbers
                           }) => {
    const getImageFromNumber = (number, x, y) => {
        let render = !!forceRender;
        if(!!forceRender && filledCells) {
            const currRelativePos = ((y - startY) * countY) + (x - startX);
            const filledRelativePos = ((filledCells.y - startY) * countY) + (filledCells.x - startX);
            render = filledRelativePos >= currRelativePos;
        }

        const selected = selectedCell && selectedCell.x === x && selectedCell.y === y;

        if(render) {
            switch (number) {
                case 35: return render35;
                case 70: return render70;
                case 137: return render137;
                case 192: return render192;
                case 248: return render248;
                default: return render35;
            }
        } else {
            switch (number) {
                case 35: return selected ? selected35 : pattern35;
                case 70: return selected ? selected70 : pattern70;
                case 137: return selected ? selected137 : pattern137;
                case 192: return selected ? selected192 : pattern192;
                case 248: return selected ? selected248 : pattern248;
                default: return selected ? selected35 : pattern35;
            }
        }

    };

    const getSubSectionData = () => {
        if (stitchData.length === 0) {
            return [];
        }

        let subSectionData = [];

        for (let y = startY; y < startY + countY; y++) {
            if (!stitchData[y]) {
                continue;
            }

            let row = [];
            for (let x = startX; x < startX + countX; x++) {
                if (stitchData[y][x] === undefined) {
                    row.push(0);
                } else {
                    row.push(stitchData[y][x]);
                }
            }
            subSectionData.push(row);
        }

        return {
            data: subSectionData,
            startX,
            startY
        };
    };

    const renderStitchGrid = () => {
        const subSectionData = getSubSectionData();
        const rows = subSectionData.data.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: 'flex' }}>
                {renderStitchCols(row, subSectionData.startX, subSectionData.startY + rowIndex)}
            </div>
        ));

        if(renderNumbers) {
            const pixelSizeStr = `${pixelSize || 20}px`
            const numberRow = [];
            for (let i = 0; i <= countX; i++) {
                numberRow.push(
                    <div key={'colNumber-' + i} style={{
                        width: pixelSizeStr,
                        height: pixelSizeStr,
                        fontSize: '13px',
                        overflow: 'hidden',
                        textAlign: 'center',
                        verticalAlign: 'bottom',
                        color: '#666666'
                    }}>
                        {i > 0 ? subSectionData.startX + i : ''}
                    </div>
                );
            }
            rows.unshift(
                <div key='colNumbers' style={{ display: 'flex' }}>
                    {numberRow}
                </div>
            );
        }
        return rows;
    };

    const renderStitchCols= (row, startColNumber, rowNumber) => {
        const pixelSizeStr = `${pixelSize || 20}px`

        const cols = row.map((color, colIndex) => (
            <div
                key={colIndex}
                style={{
                    width: pixelSizeStr,
                    height: pixelSizeStr,
                    backgroundImage: `url(${getImageFromNumber(color, startColNumber + colIndex, rowNumber)})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center'
                }}
                onClick={() => onClick && onClick(startColNumber + colIndex, rowNumber)}
            />
        ));

        if(renderNumbers) {
            cols.unshift(
                <div key='rowNumbers' style={{
                    width: pixelSizeStr,
                    height: pixelSizeStr,
                    fontSize: '13px',
                    overflow: 'hidden',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                    color: '#666666'
                }}>
                    {rowNumber + 1}
                </div>
            );
        }

        return cols;
    }


    return (
        <div>{renderStitchGrid()}</div>
    )
}