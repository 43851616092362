import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './ImageUpload.module.css';
import {styled} from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Backdrop, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPuzzlePiece, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import {StitchGrid} from "./StitchGrid";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const ImageUpload = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageRef, setImageRef] = useState(null);
    const [crop, setCrop] = useState({
        unit: '%', // Can be 'px' or '%'
        width: 90,
        height: 90,
        x: 5,
        y: 5
    });
    const [croppedImage, setCroppedImage] = useState(null);
    const [variations, setVariations] = useState([]);
    const [selectedVariationId, setSelectedVariationId] = useState(null);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(variations && variations.length > 0) {
            setSelectedVariationId(variations[0].id);
        }
    }, [variations]);

    useEffect(() => {
        setSelectedVariation(variations.find(v => v.id === selectedVariationId));
    }, [selectedVariationId]);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSelectedImage(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    function onImageLoad(e) {
        const {width, height} = e.currentTarget

        const minSide = Math.min(width, height);
        const cropSize = .95 * minSide;
        const crop = {
            unit: 'px', // Can be 'px' or '%'
            width: cropSize,
            height: cropSize,
            x: (width - cropSize) / 2,
            y: (height - cropSize) / 2,
        }

        setCrop(crop)
    }

    const onCropChange = (crop) => {
        setCrop(crop);
        setVariations([]);
        setSelectedVariationId(null);
    };

    const getCroppedImg = async () => {
        if (!imageRef || !crop.width || !crop.height) {
            throw new Error("Image or crop dimensions not set");
        }

        const scaleX = imageRef.naturalWidth / imageRef.width;
        const scaleY = imageRef.naturalHeight / imageRef.height;

        const canvas = document.createElement('canvas');
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            imageRef,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = 'newFile.jpeg';
                window.URL.revokeObjectURL(croppedImage);
                setCroppedImage(window.URL.createObjectURL(blob));
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true); // Start loading

            const croppedBlob = await getCroppedImg();
            const formData = new FormData();
            formData.append('image', croppedBlob, 'cropped.jpg');

            const response = await axios.post('/api/puzzle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setVariations(response.data.variations);
            setSelectedVariationId(null);
            setIsLoading(false); // End loading

            if(response.data.variations.length === 0) {
                alert('Пожалуйста, выберите другую фотографию. Возможно, эта фотография слишком темная или однотонная - из нее не получится собрать мозаику. Попробуйте выбрать другую фотографию.');
                resetImage();
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            setIsLoading(false); // End loading in case of error
        }
    };


    const selectVariation = (id) => {
        setSelectedVariationId(id);
    };

    const goToPage3 = () => {
        //navigate(`/stitch-pattern?id=${selectedVariationId}`);
        navigate(`/send-results?id=${selectedVariationId}`);
    };

    const resetImage = () => {
        setSelectedImage(null);
        setVariations([]);
        setSelectedVariationId(null);
    }

    return (
        <div className={styles.pageContainer}>


            {!selectedImage && (
                <Container className={[styles.flexibleContainerWithMargin, styles.fullSizeContainer]}>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon/>}
                    >
                        Выбрать фото
                        <VisuallyHiddenInput type="file" accept="image/*" onChange={onSelectFile}/>
                    </Button>
                </Container>
            )}

            {(selectedImage && !variations.length) && (
                <div className={styles.cropContainer}>
                    <ReactCrop
                        src={selectedImage}
                        crop={crop}
                        onChange={onCropChange}
                        aspect={1}
                    >
                        <img ref={setImageRef} onLoad={onImageLoad} src={selectedImage} alt="Crop me"/>
                    </ReactCrop>
                </div>
            )}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            />

            {(selectedImage && variations.length === 0) && (
                <div className={styles.initialActionContainer}>
                    <LoadingButton
                        loading={isLoading}
                        component="label"
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={faPuzzlePiece}/>}
                        onClick={handleSubmit}
                    >
                        Продолжить
                    </LoadingButton>
                </div>
            )}

            {!!selectedVariation && (
                <Box sx={{ marginBottom: '20px', textAlign: 'center'}}>
                <StitchGrid
                    startY={0}
                    startX={0}
                    countX={64}
                    countY={64}
                    stitchData={selectedVariation.pixels}
                    forceRender={true}
                    renderNumbers={false}
                    pixelSize={5}
                />
                </Box>
            )}

            {variations.length > 0 && (
                <Container className={styles.flexibleContainerWithMargin}>
                    <Box sx={{width: '100%', marginBottom: '20px', textAlign: 'center'}}>
                        <Typography variant="h2">
                            Выберите вариант
                        </Typography>
                    </Box>

                    <Container sx={{textAlign: 'center'}}>
                        {variations.map(variation => (
                            <img
                                key={variation.id}
                                src={variation.previewUrl}
                                alt="Variation"
                                onClick={() => selectVariation(variation.id)}
                                className={`${styles.variationImage} ${variation.id === selectedVariationId ? styles.selectedVariation : ''}`}
                            />
                        ))}
                    </Container>

                </Container>
            )}


            {selectedVariationId && (
                <Container className={styles.flexibleHorizontalContainerWithMargin}>
                    <Button
                        component="label"
                        variant="outlined"
                        onClick={() => resetImage()}
                        sx={{margin: '5px'}}
                    >
                        Отменить
                    </Button>
                    <Button
                        component="label"
                        variant="contained"
                        onClick={() => goToPage3()}
                        sx={{margin: '5px'}}
                    >
                        Продолжить
                    </Button>
                </Container>
            )}
        </div>
    );
};

export default ImageUpload;
