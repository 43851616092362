import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import styles from "./PrintPage.module.css";
import {Typography} from "@mui/material";
import {StitchGrid} from "./StitchGrid";

const totalSize = 64; // Total size of the stitchData (64x64)
const sectionSize = totalSize / 2; // Size of each section (32x32)
const subSectionSize = sectionSize / 2; // Size of each subsection (16x16)
export const PrintPage = () => { // Assuming stitchData is passed as a prop
    const [isLoading, setIsLoading] = useState(false);
    const [stitchData, setStitchData] = useState([]);
    const [variationId, setVariationId] = useState(null);
    const location = useLocation();


    useEffect(() => {
        console.log(location);
        const searchParams = new URLSearchParams(location.search);
        const variationId = searchParams.get('id');
        setVariationId(variationId);
    }, [location]);

    useEffect(() => {
        const fetchStitchData = async () => {
            setIsLoading(true);

            try {
                if (!variationId) {
                    console.error('No variation ID provided');
                    // Handle missing ID appropriately
                    return;
                }

                const response = await axios.get(`/api/puzzle/${variationId}/data`);
                setStitchData(response.data.data);
            } catch (error) {
                console.error('Error fetching stitch data:', error);
                // Handle error appropriately
            } finally {
                setIsLoading(false);
            }
        };

        fetchStitchData();
    }, [variationId]);

    const renderStitchGrid = (pageIndex) => {
        let startX = 0;
        let startY = 0;

        if (pageIndex === 1) {
            startX = 32
        } else if (pageIndex === 2) {
            startY = 32
        } else if (pageIndex === 3) {
            startY = 32;
            startX = 32;
        }

        return (
            <StitchGrid
                countX={32}
                countY={32}
                stitchData={stitchData}
                forceRender={false}
                pixelSize={20}
                startX={startX}
                startY={startY}
                renderNumbers={true}
            ></StitchGrid>
        )
    };

    if (stitchData.length > 0) {
        return (
            <div className={styles.printFile}>
                <div className={styles.pageContent}>
                    <StitchGrid
                        countX={64}
                        countY={64}
                        stitchData={stitchData}
                        forceRender={true}
                        pixelSize={10}
                        startX={0}
                        startY={0}
                        renderNumbers={false}
                    ></StitchGrid>
                </div>

                <div className={styles.pageContent}>
                <Typography variant={'h2'}>Основа 1 из 4-x</Typography>
                <div>{renderStitchGrid(0)}</div>
                </div>

                <div className={styles.pageContent}>
                <Typography variant={'h2'}>Основа 2 из 4-x</Typography>
                <div>{renderStitchGrid(1)}</div>
                </div>

                <div className={styles.pageContent}>
                <Typography variant={'h2'}>Основа 3 из 4-x</Typography>
                <div>{renderStitchGrid(2)}</div>
                </div>

                <div className={styles.pageContent}>
                <Typography variant={'h2'}>Основа 4 из 4-x</Typography>
                <div>{renderStitchGrid(3)}</div>
                </div>
            </div>
        )
    } else {
        return (<div>Loading</div>)
    }
};

export default PrintPage;
