import React from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styles from './Instructions.module.css';
import picEx1 from './img/pic-ex1.jpg';
import picEx2 from './img/pic-ex2.jpg';
import picEx3 from './img/pic-ex3.jpg';
import picEx4 from './img/pic-ex4.jpg';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsUp} from '@fortawesome/free-solid-svg-icons';

const ExamplePicture = ({picture}) => {
    return (
        <div className={styles.pictureContainer}>
            <img className={styles.examplePicture} src={picture}></img>
        </div>
    )
}

const Instructions = () => {
    const navigate = useNavigate();

    const navigateToImageUpload = () => {
        navigate('/image-upload');
    };

    return (
        <Container
            maxWidth="sm"
            className={styles.pageContainer}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Box sx={{width: '100%', maxWidth: 500, marginBottom: '20px', textAlign: 'center'}}>
                <Typography variant="h1">
                    Постарайтесь увеличить самое важное на фотографии объекты, например, лица
                </Typography>
            </Box>

            <Container className={styles.flexibleContainerWithMargin}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={6}>
                        <ExamplePicture picture={picEx1}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ExamplePicture picture={picEx2}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ExamplePicture picture={picEx3}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ExamplePicture picture={picEx4}/>
                    </Grid>
                </Grid>
            </Container>

            <Container className={styles.flexibleContainerWithMargin}>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faThumbsUp} />}
                    onClick={() => navigateToImageUpload()}
                >
                    Понятно
                </Button>
            </Container>
        </Container>
    );
};

export default Instructions;
