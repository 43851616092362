import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#e91e63',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontSize: '1.8rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '1.5rem',
        },
        h3: {
            fontSize: '1.2rem',
        },
        h4: {
            fontSize: '1.0rem',
        },
    },
};

export const BRICKSHOT_THEME = createTheme(themeOptions);