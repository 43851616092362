import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Instructions from './Instructions';
import ImageUpload from './ImageUpload';
import SendResults from "./SendResults";
import StitchPattern from "./StitchPattern";
import { ThemeProvider, THEME_ID } from '@mui/material/styles';
import {BRICKSHOT_THEME} from "./muiTheme";
import styles from './App.module.css';
import PrintPage from "./PrintPage";
import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: "https://f403df83a5d018959d37e77b69a2c9a6@o282044.ingest.us.sentry.io/4507297313193984",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  return (
      <ThemeProvider theme={{ [THEME_ID]: BRICKSHOT_THEME }}>
          <div className={styles.appContainer}>
              <Router>
                  <Routes>
                      <Route path="/" element={<Instructions />} />
                      <Route path="/image-upload" element={<ImageUpload />} />
                      <Route path="/send-results" element={<SendResults />} />
                      <Route path="/stitch-pattern" element={<StitchPattern />} />
                      <Route path="/print" element={<PrintPage />} />
                      {/* You can add more routes here as needed */}
                  </Routes>
              </Router>
          </div>
      </ThemeProvider>
  );
};

export default App;
