import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {TextField, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const SendResults = () => {
    const [email, setEmail] = useState('');
    const [variationId, setVariationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sendStatus, setSendStatus] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(location);
        const searchParams = new URLSearchParams(location.search);
        const variationId = searchParams.get('id');
        setVariationId(variationId);
    }, [location]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            setSendStatus(null);
            setIsLoading(true);
            // Replace with your actual API endpoint and request format
            const response = await axios.post(`/api/puzzle/${variationId}/send`, { email });
            console.log('Email sent:', response.data);
            // Additional handling after successful email sending
            setSendStatus('Успешно отправлено');
        } catch (error) {
            setSendStatus('Произошла ошибка, попробуйте позже');
            console.error('Error sending email:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadPDF = () => {
        // Implement download logic
        window.open(`/api/puzzle/${variationId}/instruction.pdf`, '_blank');
    };

    const handleStartStitching = () => {
        navigate(`/stitch-pattern?id=${variationId}`);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 500, m: 'auto' }}>
            <Typography variant="h2" gutterBottom>
                Отправить инструкцию на почту
            </Typography>
            <TextField
                fullWidth
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Введите e-mail"
                margin="normal"
                sx={{backgroundColor: '#ececec'}}
            />
            {sendStatus && (
                <Typography variant="h3" gutterBottom>
                    {sendStatus}
                </Typography>
            )}
            <LoadingButton
                variant="contained"
                loading={isLoading}
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{ mt: 2, mb: 2 }}
            >
                Отправить
            </LoadingButton>
            <Button
                variant="text"
                onClick={handleDownloadPDF}
                sx={{ mt: 2, mb: 2 }}
            >
                Скачать инструкцию для печати (PDF)
            </Button>
            <Button
                variant="text"
                onClick={handleStartStitching}
                sx={{ mt: 2, mb: 2 }}
            >
                Открыть инструкцию на устройстве
            </Button>
        </Box>
    );
};

export default SendResults;
